import React from 'react'
import { cx, css } from 'linaria'
import { Link } from 'gatsby'
import { ReactComponent as SvgAppStore } from '@components/svg/download-app-store.svg'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { ProductItemType } from '@/types'

const IntroItem = ({
  name,
  subtitle,
  shortDesc,
  appStoreUrl,
  imageRegex,
  detailPath,
  introBGClass,
}: ProductItemType) => {
  const data = useStaticQuery(graphql`
    query {
      allMobileLogos: allFile(filter: { absolutePath: { regex: "/product/logo/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fixed(width: 80, height: 80) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      }
      allDesktopLogos: allFile(filter: { absolutePath: { regex: "/product/logo/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fixed(width: 150, height: 150) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
  const mobileLogo = data.allMobileLogos.edges.find(({ node }) => {
    return node.relativePath.includes(imageRegex)
  })
  const desktopLogo = data.allDesktopLogos.edges.find(({ node }) => {
    return node.relativePath.includes(imageRegex)
  })
  const sources = [
    mobileLogo.node.childImageSharp.fixed,
    {
      ...desktopLogo.node.childImageSharp.fixed,
      media: `(min-width: 1024px)`,
    },
  ]
  return (
    <div
      className={cx(
        introBGClass,
        'flex flex-col md:flex-row md:justify-between md:items-center p-5 mb-5',
        css`
          border: 1px solid #dbe2e6;
          border-radius: 8px;
          margin-bottom: 20px;

          @media (min-width: 768px) {
            padding: 74px 100px 74px 75px;
            margin-bottom: 32px;
          }
        `
      )}
    >
      <div
        className={cx(
          'flex-none',
          css`
            margin-right: 20px;
            @media (min-width: 768px) {
              margin-right: 75px;
            }
          `
        )}
      >
        <Img fixed={sources} />
      </div>
      <div className="flex-grow">
        <h2
          className={cx(
            'font-bold text-secondary-black leading-none font-header',
            css`
              font-weight: 700;
              font-size: 24px;
              margin-bottom: 7px;
              @media (min-width: 768px) {
                margin-bottom: 12px;
                font-size: 28px;
              }
            `
          )}
        >
          {name}
        </h2>
        <h3
          className={cx(css`
            font-size: 21px;
            font-weight: 500;
            margin-bottom: 12px;
            @media (max-width: 768px) {
              font-size: 16px;
            }
          `)}
        >
          {subtitle}
        </h3>
        <p
          className={cx(
            'text-base md:text-lg mb-3 md:mb-8',
            css`
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            `
          )}
        >
          {shortDesc}
        </p>
        <div className="flex items-center">
          {appStoreUrl && (
            <a href={appStoreUrl} target="blank" rel="noopener noreferer" className="mr-3">
              <SvgAppStore />
            </a>
          )}
          <Link
            to={detailPath}
            title={`Learn more about ${name}`}
            className={cx(
              'btn btn-primary text-sm',
              css`
                width: 110px;
                height: 34px;
              `
            )}
          >
            Learn more
          </Link>
        </div>
      </div>
    </div>
  )
}

export default IntroItem
