import React from 'react'
import { cx, css } from 'linaria'
import { productList } from '@components/contants'
import IntroItem from './intro_item'

export const Intro = () => {
  return (
    <div
      className={cx(
        'box-border container mt-5 mb-10 md:my-20 px-4',
        css`
          max-width: 62rem;
        `
      )}
    >
      {productList.map(introItem => (
        <IntroItem key={introItem.name} {...introItem} />
      ))}
    </div>
  )
}
