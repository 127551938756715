import React from 'react'

import Seo from '@components/seo'
import { Base } from '@ui/base'
import { Header } from '@components/ui/header'
import { Intro } from '@components/all-products/intro'
import { Footer } from '@components/ui/footer'
import { Page } from 'gatsby'

const AllProductsPage: React.FC = ({ path }: Page) => {
  return (
    <>
      <Seo
        title="Everything you need to clean up the plate"
        description={`Working processes inevitably leave us with minor and major disturbance. And sometimes it’s hard to create a vibe to fully concentrate. We’ve crafted up a bunch of cool stuff to provide users with minimalism & productivity setup.`}
      />

      <Base>
        <Header bgColor="bg-white" />
        <Intro />
        <Footer path={path} />
      </Base>
    </>
  )
}

export default AllProductsPage
